import { ReactNode } from 'react';
import LoadingBubbles from '../../../assets/loading-bubbles.svg';

export default function AppShowLoading({
  inline,
  showLoading,
  children,
  width,
}: {
  inline?: boolean,
  showLoading: boolean,
  children?: ReactNode,
  width?: number,
}) {
  const balls = (
    <img
      style={inline ? undefined : {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 110px)',
      }}
      width={width}
      className="app-show-loading-image"
      src={LoadingBubbles}
      alt="Loading..."
    />
  );

  return (
    <>
      {
        showLoading ? balls : children
      }
    </>
  );
}
