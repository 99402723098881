import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useAutopaymentsStripeData, useOneTimePaymentStripeData } from '../../../shared/appUIFramework/appBackend/useStripe';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { useClaims } from '../../../shared/backend/auth/claims';

export enum StripeVariant {
  SetupAutopayments = 'SetupAutopayments',
  OneTimePayment = 'OneTimePayment',
}

export default function StripePopup({
  stripeVariant,
  invoiceId,
}: {
  stripeVariant: StripeVariant,
  invoiceId?: number,
}) {
  const { customerReference } = useClaims();
  const { stripeConfig } = stripeVariant === StripeVariant.SetupAutopayments
    ? useAutopaymentsStripeData(customerReference)
    : useOneTimePaymentStripeData(invoiceId!);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (customerReference && stripeConfig) {
      setStripePromise(loadStripe(stripeConfig.PublicKey));
    }
  }, [customerReference, stripeConfig]);

  const closeWithFalse = () => { closePopup(false); };
  const closeWithTrue = () => { closePopup(true); };

  const titleKey = stripeVariant === StripeVariant.SetupAutopayments
    ? 'SetupAutomaticPayments'
    : 'MakeOneTimePayment';

  return (
    <AppPopupLayout
      headerTitle={titleKey}
      extraClass="app-popup-worldpay"
      onHeaderClose={closeWithFalse}
      popupBody={!stripeConfig || !stripePromise ? <></> : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            clientSecret: stripeConfig.ClientSecret,
            onComplete: () => {
              closeWithTrue();
            },
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    />
  );
}
