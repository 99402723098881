import { useTranslation } from 'react-i18next';
import ErrorPage from '../components/ErrorPage';
import { ReactComponent as ErrorRobot403Graphic } from '../../../assets/errorGraphics/Error_Robot_403.svg';
import { useClaims } from '../../backend/auth/claims';
import { LangEmailMap, useAppLocalisation } from '../../i18n/config';

export default function AccessForbidden() {
  const { t } = useTranslation();
  const { lang } = useAppLocalisation();
  const claims = useClaims();

  const props = claims.errorCode
    ? {
      errorCodeTranslated: claims.errorCode,
      errorMessageTranslatedd: t('AnInternalErrorAccoureTryAgainLaterForSign', { email: LangEmailMap[lang] }),
    } : {
      errorCode: 'Errors_403',
      errorMessage: 'Errors_403Message',
    };

  return (
    <ErrorPage {...props} errorGraphic={<ErrorRobot403Graphic />} />
  );
}
