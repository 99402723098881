/* eslint-disable max-len */
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useLogout } from '../hooks/useLogout';
import { AppPopupLayout } from '../popup/AppPopupLayout';
import AppPricePerUser from '../components/AppPricePerUser';
import styles from './FirstTimeSignedInPopup.module.scss';
import { PaymentType, useCurrentAdmin } from '../appBackend/useAdminsInfinite';
import { handleHttpRequestError } from '../appBackend/errorHandling/useSWRAndHandleErrors';
import LoadingBubbles from '../../../assets/loading-bubbles.svg';
import { ReactComponent as InvoiceDueIcon } from '../../../assets/InvoiceDueGBP.svg';
import { useClaims } from '../../backend/auth/claims';
import '../../../routes/payment/PaymenInvoicePdf.scss';
import { closePopup, showPopup } from '../popup/AppPopup';
import AppHelpPopup from '../components/AppHelpPopup';
import { useTermsAndConditions } from '../appBackend/useTermsAndConditions';
import AppShowLoading from '../components/AppShowLoading';
import callIfEnterKeyPressed from '../../jsUtils/callIfEnterKeyPressed';
import { useIsMobileLayout } from '../hooks/useIsMobileLayout';
import {
  acceptTermsAndConditions, setCompanyFields, submitVatNumber, uploadTaxCertificate,
} from '../appBackend/useCompany';
import TaxCertificateDropzone, {
  IUploadTaxCertificateProps,
} from '../components/uploadTaxCertificate/TaxCertificateDropzone';
import { useCustomerPricing } from '../appBackend/useAccountCharges';
import { AccountType, IInternalAppsCompanyAddress } from '../appBackend/useKeyContact';
import callIfEscapeKeyPressed from '../../jsUtils/callIfEscapeKeyPressed';
import { isTaxCodeValid } from '../../../routes/signup/signup.api';
import { useAutopaymentsStripeData } from '../appBackend/useStripe';

interface IStepConfig {
  title: string,
  subtitle: string,
  canContinue: boolean
  children: ReactNode,
  extraClass?: string,
  onStepLeave?: () => void,
}

function useStepsIndex(stepsCount: number) {
  const maxStepIndex = stepsCount - 1;
  const minStepIndex = 0;

  const [stepIndex, setStepIndex] = useState(minStepIndex);

  const next = () => {
    if (stepIndex < maxStepIndex) {
      setStepIndex(stepIndex + 1);
    }
  };
  const prev = () => {
    if (stepIndex > minStepIndex) {
      setStepIndex(stepIndex - 1);
    }
  };

  return {
    stepIndex,
    next,
    prev,
    isLastStep: stepIndex === maxStepIndex,
    isFirstPage: stepIndex === minStepIndex,
  };
}

function usePricingStep() {
  const { t } = useTranslation();
  const { admin } = useCurrentAdmin();
  const { costs } = useCustomerPricing();
  return {
    title: t('TermsAndConditions_WelcomeToMyPaxton', { name: admin?.firstName }),
    subtitle: t('TermsAndConditions_LetsGetYouSetUp'),
    canContinue: true,
    children: (
      <div className={styles.appPopupBody}>
        <div className={styles.pricingHeading}>
          {t('TermsAndConditions_Step1')}
        </div>
        <div className={styles.pricingSubheading}>
          {t('TermsAndConditions_FirstThingsFirstPricing')}
        </div>
        <div className={styles.pricingText}>
          {t('TermsAndConditions_PricingMessage')}
        </div>
        {!costs && <div className="app-d-flex app-align-items-center app-justify-content-center"><AppShowLoading showLoading inline /></div>}
        {costs && <AppPricePerUser costs={costs.value} currency={costs.currency} />}
      </div>
    ),
    extraClass: styles.pricingPage,
  };
}

function useTermsAndConditionsStep() {
  const { t } = useTranslation();
  const [agreedToTos, setAgreedToTos] = useState(false);
  const tosContainerRef = useRef<HTMLDivElement>(null);
  const termsAndConditionsHtml = useTermsAndConditions();

  return {
    title: t('TermsAndConditions_Step2'),
    subtitle: t('TermsAndConditions_TheImportantButBoringBitTAndCs'),
    canContinue: agreedToTos,
    children: (
      <>
        <div
          ref={tosContainerRef}
          className={`${styles.appPopupBody} ${styles.tosContainer}`}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHtml || '' }} />
        </div>
        <div className={`${styles.agreeToTosCheckbox}`}>
          <input
            id="agreeToTos"
            type="checkbox"
            checked={agreedToTos}
            onChange={(e) => setAgreedToTos(e.target.checked)}
          />
          <label htmlFor="agreeToTos">{t('TermsAndConditions_AcceptTermsAndConditions')}</label>
        </div>
      </>),
  };
}

function UploadTaxCertificate(props: IUploadTaxCertificateProps) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="app-uppercase app-weight-600 app-mp-bold-16 app-mb-7">
        <span>{t('TaxCertificate')}</span>
        <span className="app-text-secondary-red-color">&nbsp;*</span>
      </div>
      <TaxCertificateDropzone {...props} />
    </div>
  );
}

function VatNumberInput({
  currentVat,
  changeVatNumber,
  vatNumberValid,
  vatNumberTouched,
}: {
  currentVat: string,
  changeVatNumber: (value: string) => void,
  vatNumberValid: boolean,
  vatNumberTouched: boolean,
}) {
  const { t } = useTranslation();
  return (
    <div className="app-form-control" aria-invalid={vatNumberTouched && !vatNumberValid}>
      <div className="app-form-control-label">
        {t('VatNumber')}
        <span className="app-input-required">&nbsp;*</span>
      </div>
      <div className="app-form-control-input app-vat-number-input">
        <input
          type="text"
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
          className="app-form-input"
          defaultValue={currentVat}
          onChange={(e) => {
            changeVatNumber(e.target.value);
          }}
          placeholder={t('VatNumber')}
        />
      </div>
      {vatNumberTouched && !vatNumberValid && currentVat.trim().length === 0 && (
        <div className="app-signup-input-error">
          {t('Form_FieldRequired')}
        </div>
      )}
      {vatNumberTouched && !vatNumberValid && currentVat.trim().length !== 0 && (
        <div className="app-signup-input-error">
          {t('VatNumberIsInvalid')}
        </div>
      )}
    </div>
  );
}

function WorldpayLoading() {
  return (
    <div
      className="app-d-flex app-h-100pcnt app-align-items-center app-justify-content-center app-w-50pcnt app-position-relative"
    >
      <AppShowLoading showLoading />
    </div>
  );
}

function StripeSetup({
  setAutopaymentSetupCompleted,
  autopaymentSetupCompleted,
}: {
  setAutopaymentSetupCompleted: (value: boolean) => void,
  autopaymentSetupCompleted: boolean,
}) {
  const { customerReference } = useClaims();
  const { stripeConfig } = useAutopaymentsStripeData(customerReference);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (customerReference && stripeConfig) {
      setStripePromise(loadStripe(stripeConfig.PublicKey));
    }
  }, [customerReference, stripeConfig]);

  return !stripeConfig || !stripePromise ? <WorldpayLoading /> : (
    <>
      {!autopaymentSetupCompleted
        && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: stripeConfig.ClientSecret,
              onComplete: () => {
                setAutopaymentSetupCompleted(true);
              },
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
    </>
  );
}

function PaymentTypePageNode({
  isTaxCertificateRequired,
  isOnlyAutopaymentEnabled,
  paymentType,
  setPaymentType,
  isVatNumberNeeded,
  changeVatNumber,
  isVatNumberValid,
  uploadInProgress,
  setUploadInProgress,
  certificateFileName,
  setCertificateFileName,
  currentVat,
  vatNumberTouched,
}
  : {
    isOnlyAutopaymentEnabled: boolean,
    isTaxCertificateRequired: boolean,
    paymentType: PaymentType | null,
    setPaymentType: (value: PaymentType) => void,
    isVatNumberNeeded: boolean,
    changeVatNumber: (value: string) => void,
    isVatNumberValid: boolean,
    uploadInProgress: boolean,
    setUploadInProgress: (value: boolean) => void,
    certificateFileName: string,
    setCertificateFileName: (value: string) => void,
    currentVat: string,
    vatNumberTouched: boolean,
  }) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.paymentType} ${isTaxCertificateRequired ? styles.taxCertificateRequired : ''}`}>
      <label htmlFor="automaticPayments">
        <div className={styles.radioText}>
          <div className={`${styles.labelHeader} app-pb-10`}>{t('TermsAndConditions_AutomaticPayments')}</div>
          <div className={`${styles.labelBody} app-pb-10`}>{t('TermsAndConditions_AutomaticPaymentsMessage')}</div>
        </div>
        {!isOnlyAutopaymentEnabled && (
          <input
            id="automaticPayments"
            type="radio"
            name="paymentType"
            checked={paymentType === PaymentType.Automatic}
            onChange={(e) => {
              if (e.target.checked) {
                setPaymentType(PaymentType.Automatic);
              }
            }}
          />
        )}
      </label>
      {!isOnlyAutopaymentEnabled && (
        <label htmlFor="manualPayments">
          <div className={styles.radioText}>
            <div className={styles.labelHeader}>{t('TermsAndConditions_ManualPayments')}</div>
            <div className={styles.labelBody}>{t('TermsAndConditions_ManualPaymentsMessage')}</div>
          </div>
          <input
            id="manualPayments"
            type="radio"
            name="paymentType"
            checked={paymentType === PaymentType.Manual}
            onChange={(e) => {
              if (e.target.checked) {
                setPaymentType(PaymentType.Manual);
              }
            }}
          />
        </label>
      )}
      {isTaxCertificateRequired && (
        <UploadTaxCertificate
          showIcon={false}
          showLoading={uploadInProgress}
          fileName={certificateFileName}
          onFileRemoved={() => setCertificateFileName('')}
          onFileSelected={async (file) => {
            try {
              setUploadInProgress(true);
              await uploadTaxCertificate(file);
              setCertificateFileName(file.name);
            } catch (e) {
              await handleHttpRequestError(e);
            } finally {
              setUploadInProgress(false);
            }
          }}
        />
      )}
      {
        isVatNumberNeeded && (
          <VatNumberInput
            changeVatNumber={changeVatNumber}
            vatNumberValid={isVatNumberValid}
            currentVat={currentVat}
            vatNumberTouched={vatNumberTouched}
          />
        )
      }
    </div>
  );
}

function PaymentTypeSet({
  paymentType,
  setAutopaymentSetupCompleted,
  autopaymentSetupCompleted,
}: {
  paymentType: PaymentType | null,
  setAutopaymentSetupCompleted: (value: boolean) => void,
  autopaymentSetupCompleted: boolean,
}) {
  return paymentType === PaymentType.Automatic
    ? (
      <StripeSetup
        setAutopaymentSetupCompleted={setAutopaymentSetupCompleted}
        autopaymentSetupCompleted={autopaymentSetupCompleted}
      />
    )
    : (
      <div className={styles.appInvoicePdf}>
        <InvoiceDueIcon />
      </div>
    );
}

function CardDetailsPageNode({
  paymentType,
  setAutopaymentSetupCompleted,
  autopaymentSetupCompleted,
}: {
  paymentType: PaymentType | null,
  setAutopaymentSetupCompleted: (value: boolean) => void,
  autopaymentSetupCompleted: boolean,
}) {
  return (
    <>
      <div className={styles.cardDetailsPage}>
        {paymentType != null ? (
          <PaymentTypeSet
            paymentType={paymentType}
            setAutopaymentSetupCompleted={setAutopaymentSetupCompleted}
            autopaymentSetupCompleted={autopaymentSetupCompleted}
          />
        )
          : (
            <div className={styles.appInvoicePdf}>
              <InvoiceDueIcon />
            </div>
          )}
      </div>
    </>
  );
}

function usePaymentTypesSteps(isOnlyAutopaymentEnabled: boolean, isTaxCertificateRequired: boolean, isVatNumberNeeded: boolean, setVatNumber: (value: string) => void, currentVat: string) {
  const { isMobileLayout } = useIsMobileLayout();
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null);
  const [certificateFileName, setCertificateFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [vatNumberValid, setVatNumberValid] = useState(!isVatNumberNeeded);
  const [autopaymentSetupCompleted, setAutopaymentSetupCompleted] = useState(false);
  const { customerReference } = useClaims();

  useEffect(() => {
    if (isOnlyAutopaymentEnabled) {
      setPaymentType(PaymentType.Automatic);
    }
  }, []);

  const callIdRef = useRef(0);
  const [vatNumberTouched, setVatNumberTouched] = useState(false);
  const changeVatNumber = async (value: string) => {
    callIdRef.current += 1;
    const originalCallId = callIdRef.current;

    const notEmpty = value.trim() !== '';
    const isValid = notEmpty ? await isTaxCodeValid(value, customerReference) : false;

    if (originalCallId < callIdRef.current) {
      return;
    }

    setVatNumber(value);
    setVatNumberValid(isValid);
    setVatNumberTouched(true);
  };

  const isPaymentTypeValid = paymentType !== PaymentType.Automatic || autopaymentSetupCompleted;
  const isTaxCertificatedValid = !isTaxCertificateRequired || certificateFileName !== '';
  const steps: IStepConfig[] = isMobileLayout ? [
    {
      title: t('TermsAndConditions_TheFinalStep'),
      subtitle: t('TermsAndConditions_TheHardPartSettingUpPayment'),
      canContinue: paymentType !== null && vatNumberValid && isTaxCertificatedValid,
      children: <PaymentTypePageNode
        paymentType={paymentType}
        isOnlyAutopaymentEnabled={isOnlyAutopaymentEnabled}
        setPaymentType={setPaymentType}
        isTaxCertificateRequired={isTaxCertificateRequired}
        changeVatNumber={changeVatNumber}
        isVatNumberValid={vatNumberValid}
        uploadInProgress={uploadInProgress}
        setUploadInProgress={setUploadInProgress}
        certificateFileName={certificateFileName}
        setCertificateFileName={setCertificateFileName}
        currentVat={currentVat}
        isVatNumberNeeded={isVatNumberNeeded}
        vatNumberTouched={vatNumberTouched}
      />,
    },
    {
      title: t('TermsAndConditions_TheFinalStep'),
      subtitle: t('TermsAndConditions_TheHardPartSettingUpPayment'),
      canContinue: true,
      onStepLeave: () => {
      },
      children: <CardDetailsPageNode
        paymentType={paymentType}
        setAutopaymentSetupCompleted={setAutopaymentSetupCompleted}
        autopaymentSetupCompleted={autopaymentSetupCompleted}
      />,
    },
  ] : [
    {
      title: t('TermsAndConditions_TheFinalStep'),
      subtitle: t('TermsAndConditions_TheHardPartSettingUpPayment'),
      canContinue: paymentType !== null && vatNumberValid && isTaxCertificatedValid,
      onStepLeave: () => {
      },
      children: (
        <div className={styles.desktopPaymentPage}>
          <PaymentTypePageNode
            paymentType={paymentType}
            isOnlyAutopaymentEnabled={isOnlyAutopaymentEnabled}
            setPaymentType={setPaymentType}
            isTaxCertificateRequired={isTaxCertificateRequired}
            changeVatNumber={changeVatNumber}
            isVatNumberValid={vatNumberValid}
            uploadInProgress={uploadInProgress}
            setUploadInProgress={setUploadInProgress}
            certificateFileName={certificateFileName}
            setCertificateFileName={setCertificateFileName}
            currentVat={currentVat}
            isVatNumberNeeded={isVatNumberNeeded}
            vatNumberTouched={vatNumberTouched}
          />
          <CardDetailsPageNode
            paymentType={paymentType}
            setAutopaymentSetupCompleted={setAutopaymentSetupCompleted}
            autopaymentSetupCompleted={autopaymentSetupCompleted}
          />
        </div>),
    },
  ];

  return {
    steps, paymentType, canFinish: isPaymentTypeValid && isTaxCertificatedValid && vatNumberValid,
  };
}

function useSteps(isOnlyAutopaymentEnabled: boolean, isTaxCertificatedRequired: boolean, isVatNumberNeeded: boolean, setVatNumber: (value: string) => void, currentVat: string) {
  const {
    steps: paymentTypeSteps, paymentType, canFinish,
  } = usePaymentTypesSteps(isOnlyAutopaymentEnabled, isTaxCertificatedRequired, isVatNumberNeeded, setVatNumber, currentVat);
  const steps: IStepConfig[] = [
    usePricingStep(),
    useTermsAndConditionsStep(),
    ...paymentTypeSteps,
  ];

  return {
    stepsCount: steps.length,
    steps,
    paymentType,
    canFinish,
  };
}

function useFinish(
  paymentType: PaymentType | null,
  vatNumber: string,
  address: IInternalAppsCompanyAddress,
  accountType: AccountType,
) {
  const [loading, setLoading] = useState(false);
  const finish = async () => {
    if (paymentType == null) {
      return;
    }

    try {
      setLoading(true);
      await acceptTermsAndConditions();
      await setCompanyFields(address, accountType);
      if (vatNumber) {
        await submitVatNumber(vatNumber);
      }
    } catch (err) {
      handleHttpRequestError(err);
    } finally {
      setLoading(false);
      closePopup();
      await showPopup(<AppHelpPopup />);
    }
  };

  return { finish, loading };
}

export default function FirstTimeSignedInPopup({
  isTaxCertificatedRequired,
  isVatNumberNeeded,
  isOnlyAutopaymentEnabled,
  address,
  accountType,
}:
  {
    isTaxCertificatedRequired: boolean,
    isVatNumberNeeded: boolean,
    isOnlyAutopaymentEnabled: boolean,
    address: IInternalAppsCompanyAddress,
    accountType: AccountType
  }) {
  const { t } = useTranslation();
  const [vatNumber, setVatNumber] = useState('');
  const {
    steps, stepsCount, paymentType, canFinish,
  } = useSteps(isOnlyAutopaymentEnabled, isTaxCertificatedRequired, isVatNumberNeeded, setVatNumber, vatNumber);
  const {
    stepIndex, next, prev, isLastStep, isFirstPage,
  } = useStepsIndex(stepsCount);
  const step = steps[stepIndex];

  const logout = useLogout();

  const { finish, loading: finishInProgress } = useFinish(paymentType, vatNumber, address, accountType);

  const onRightButtonClick = isLastStep ? finish : next;
  const rightButtonDisabled = !step.canContinue
    || finishInProgress
    || (isLastStep && !canFinish);

  const onLeftButtonClicked = isFirstPage
    ? logout
    : (() => {
      if (step.onStepLeave) {
        step.onStepLeave();
      }
      prev();
    });

  return (
    <AppPopupLayout
      extraClass={`${styles.termsAndConditionsWizard} ${step.extraClass}`}
      headerTitle={step.title}
      headerSubtitle={step.subtitle}
      popupBody={(
        <>
          {finishInProgress && (
            <div className="app-connect-device-popup-loading-wrapper">
              <img
                className="app-show-loading-image"
                src={LoadingBubbles}
                alt="Loading..."
              />
            </div>
          )}
          <div className={`${styles.mainContainer} ${finishInProgress ? 'app-invisible' : ''}`}>
            {step.children}
          </div>
        </>
      )}
      buttons={(
        <>
          <button
            type="button"
            disabled={finishInProgress}
            className="app-secondary-button app-popup-left-button"
            tabIndex={0}
            onKeyDown={callIfEscapeKeyPressed(onLeftButtonClicked)}
            onClick={onLeftButtonClicked}
          >
            {isFirstPage ? t('Cancel') : t('Back')}
          </button>
          <div className={styles.pagination}>
            {[...steps.keys()].map((index) => (
              <div
                key={index}
                className={`${styles.bullet} ${index === stepIndex ? styles.selected : ''}`}
              />
            ))}
          </div>
          <button
            type="button"
            disabled={rightButtonDisabled}
            className="app-primary-button app-popup-right-button"
            tabIndex={0}
            onKeyDown={callIfEnterKeyPressed(onRightButtonClick)}
            onClick={onRightButtonClick}
          >
            {isLastStep ? t('Finish') : t('Next')}
          </button>
        </>
      )}
    />
  );
}
