import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function PaymentFailedManualContent({ notification }: { notification: IAppNotification }) {
  const { t } = useTranslation();
  const { invoiceNumber } = JSON.parse(notification.jsonPayload) as { invoiceNumber: string };

  return (
    <BaseNotificationContent
      title={t('PaymentFailed')}
      description={t('PaymentFailedDescription', { invoiceNumber })}
      linkText={t('UpdateCardDetails')}
      createdAt={notification.createdAt}
    />
  );
}
