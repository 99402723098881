import { useAppLocalisation } from '../../i18n/config';
import { B2CLangCode } from '../constants/B2CLangCode';

function formatNumber(value?: number | null, options?: { lang: B2CLangCode }) {
  if (value == null) {
    return '';
  }

  return value.toLocaleString(options?.lang);
}

export function useFormatNumber() {
  const { lang } = useAppLocalisation();

  return (value?: number | null) => formatNumber(value, { lang });
}
