import i18n from 'i18next';
import { CurrencyCode, CurrencyCodeString, CurrencyCodeSymbol } from '../appBackend/useInvoices';
import { CURRENCY_DECIMALS_AMOUNT } from '../constants/constants';
import { roundAsCurrencyOnUI } from '../../jsUtils/roundToDecimals';

function getFormattedMoneyOrPlaceholder(amount: number, currencySymbol: CurrencyCodeSymbol): string {
  if (Number.isNaN(amount)) {
    return i18n.t('-');
  }

  const roundedToTwoDecimals = +roundAsCurrencyOnUI(amount).toFixed(CURRENCY_DECIMALS_AMOUNT);
  const formatted = roundedToTwoDecimals.toLocaleString(i18n.language, {
    minimumFractionDigits: CURRENCY_DECIMALS_AMOUNT,
  });

  return `${currencySymbol}${formatted}`;
}

function getCurrencySymbol(currencyCode: CurrencyCode | CurrencyCodeString) {
  switch (currencyCode) {
    case CurrencyCode.USD:
    case CurrencyCodeString.USD:
      return CurrencyCodeSymbol.USD;
    case CurrencyCode.GBP:
    case CurrencyCodeString.GBP:
      return CurrencyCodeSymbol.GBP;
    case CurrencyCode.EUR:
    case CurrencyCodeString.EUR:
      return CurrencyCodeSymbol.EUR;
    default: return currencyCode;
  }
}

export function formatMoney(currencyCode: CurrencyCode | CurrencyCodeString, amount: number): string {
  const currencySymbol = getCurrencySymbol(currencyCode);

  return getFormattedMoneyOrPlaceholder(amount, currencySymbol);
}
