import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { httpGetJson } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';

export interface IStripeConfig {
  PublicKey: string;
  ClientSecret: string;
}

export function useAutopaymentsStripeData(customerReference: string): { stripeConfig: IStripeConfig | undefined } {
  const url = Urls.StripeAutoToken(customerReference);

  const { data } = useSWRAndHandleErrors<IStripeConfig>(url, httpGetJson, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
  });

  const { cache } = useSWRConfig();
  useEffect(() => () => {
    cache.delete(url);
  }, []);

  return {
    stripeConfig: data,
  };
}

export function useOneTimePaymentStripeData(invoiceId: string | number): { stripeConfig: IStripeConfig | undefined } {
  const url = Urls.StripeInvoice(invoiceId);

  const { data } = useSWRAndHandleErrors<IStripeConfig>(url, httpGetJson, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
  });

  const { cache } = useSWRConfig();
  useEffect(() => () => {
    cache.delete(url);
  }, []);

  return {
    stripeConfig: data,
  };
}
