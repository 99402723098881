export enum LocalStorageKey {
  AppLanguage = 'language',
  Logout = 'logout',
  PasswordReset = 'PRST',
  SoloUi = 'soloUi',
}

export function getLocalStorageManagerString(key: LocalStorageKey) {
  return {
    value: localStorage.getItem(key),
    setValue: (value: string) => { localStorage.setItem(key, value); },
    resetValue: () => { localStorage.removeItem(key); },
  };
}

export function useLocalStorageManagerString(key: LocalStorageKey) {
  return getLocalStorageManagerString(key);
}

export function useLocalStorageJsonValue<T>(key: LocalStorageKey) {
  const keyStringValue = getLocalStorageManagerString(key);

  return {
    value: JSON.parse(keyStringValue.value ?? '{}') as T,
    setValue: (value: T) => { localStorage.setItem(key, JSON.stringify(value)); },
    resetValue: () => { localStorage.removeItem(key); },
  };
}
