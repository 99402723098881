import { PublicClientApplication } from '@azure/msal-browser';
import { B2CLangCode } from '../../appUIFramework/constants/B2CLangCode';
import { FailedToGetTokenError } from '../errors/failedToGetTokenError';
import { IApplicationClaims, setClaims } from './claims';
import { msalConfig } from './msal.config';
import { getPasswordChangeStatus, PasswordChangeStatus, sendChangePasswordEmail } from './passwordChangeUtils';
import { StatusCodesEnum } from '../../appUIFramework/constants/StatusCodesEnum';

const b2cScope = process.env.REACT_APP_B2C_SCOPE!;
const envBaseUrl = process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT!;
const LogoutErrorUrl = 'error';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAccount = () => msalInstance.getAllAccounts()[0];

export async function logout() {
  await msalInstance.logoutRedirect();
}

export async function logoutWithError(code: number) {
  await msalInstance.logoutRedirect({
    postLogoutRedirectUri: `${envBaseUrl}/${LogoutErrorUrl}/${code}`,
  });
}

function getSiteIds(idTokenClaims: any) {
  const maxSiteIdsChunks = 8;
  const siteIds = [];
  for (let i = 0; i < maxSiteIdsChunks; i += 1) {
    if (idTokenClaims[`extension_SiteIds${i}`]) {
      siteIds.push(...JSON.parse(idTokenClaims[`extension_SiteIds${i}`]));
    }
  }

  return siteIds;
}

export async function goToLoginPage(b2cLangCode: B2CLangCode, goToBaseUrlAfterLogin?: boolean) {
  await msalInstance.loginRedirect({
    scopes: ['openid'],
    extraQueryParameters: {
      ui_locales: b2cLangCode,
    },
    // RedirectStartPage and redirectUri needed to make set the redirect after login to stop error (EA-2125)
    // This only works if both are set not just one of them
    redirectStartPage: goToBaseUrlAfterLogin ? envBaseUrl : undefined,
    redirectUri: goToBaseUrlAfterLogin ? envBaseUrl : undefined,
  });
}

export async function initClaims(): Promise<IApplicationClaims> {
  try {
    const { idTokenClaims, uniqueId: userId } = await msalInstance.acquireTokenSilent({
      account: getAccount(),
      scopes: [
        `${b2cScope}`,
      ],
    });

    const passwordChangeStatus = getPasswordChangeStatus(idTokenClaims);

    // @ts-ignore
    if (!idTokenClaims.extension_CompanyId && passwordChangeStatus === PasswordChangeStatus.Unchanged) {
      // @ts-ignore
      const errorCode = idTokenClaims.extension_ErrorCode || StatusCodesEnum.Forbidden;
      await logoutWithError(errorCode);
      throw new FailedToGetTokenError();
    }

    if (passwordChangeStatus === PasswordChangeStatus.ChangedViaForgot
      || passwordChangeStatus === PasswordChangeStatus.ChangedViaChange) {
      await sendChangePasswordEmail(userId);
      await logout();
    }

    const claims = {
      // @ts-ignore
      companyId: idTokenClaims.extension_CompanyId,
      // @ts-ignore
      permissions: JSON.parse(idTokenClaims.extension_AppPermissions),
      // @ts-ignore
      customerReference: idTokenClaims.extension_IA_CustomerReferenceId,
      // @ts-ignore
      branchOfficeId: idTokenClaims.extension_IA_BranchOfficeId,
      // @ts-ignore
      locale: idTokenClaims.extension_Locale,
      userId,
      // @ts-ignore
      userRole: idTokenClaims.extension_ApplicationRole,
      // @ts-ignore
      accountStatus: idTokenClaims.extension_AccountStatus,
      siteIds: getSiteIds(idTokenClaims),
      // @ts-ignore
      errorCode: idTokenClaims.extension_ErrorCode,
    } as IApplicationClaims;
    setClaims(claims);
    return claims;
  } catch (err) {
    if (err instanceof FailedToGetTokenError) {
      throw err;
    }

    throw new FailedToGetTokenError();
  }
}

export async function getAccessToken() {
  try {
    const { accessToken } = await msalInstance.acquireTokenSilent({
      account: getAccount(),
      scopes: [
        `${b2cScope}`,
      ],
    });

    return accessToken;
  } catch (err) {
    throw new FailedToGetTokenError();
  }
}
