import { Urls } from '../../backend/urls';
import { httpDelete } from '../../backend/http/http';
import { InternalAppsCompany, useInternalAppsCompanyDetails } from './useKeyContact';

export interface IAutoPaymentInfo {
  autoPaymentEnabled: boolean;
  cardType?: string;
  last4Digits?: string;
}

interface IAutoPaymentInfoResponse {
  cardType: string;
  maskedCardNumber: string;
}

export function useAutoPaymentInfo(): {
  paymentsInfo?: IAutoPaymentInfo,
  updatePaymentInfo: () => Promise<InternalAppsCompany | undefined>,
  setPaymentInfo: (newPaymentInfo: IAutoPaymentInfoResponse) => void,
  } {
  const { data: internalAppsCompany, mutate } = useInternalAppsCompanyDetails();

  return {
    paymentsInfo: {
      autoPaymentEnabled: internalAppsCompany?.cardType != null,
      cardType: internalAppsCompany?.cardType,
      last4Digits: internalAppsCompany?.maskedCardNumber?.slice(-4),
    },
    updatePaymentInfo: () => mutate(() => undefined),
    setPaymentInfo: (paymentInfoResponse: IAutoPaymentInfoResponse) => mutate(
      () => ({ ...internalAppsCompany!, ...paymentInfoResponse }),
      {
        revalidate: false,
        populateCache: true,
        optimisticData: ({ ...internalAppsCompany!, ...paymentInfoResponse }),
      },
    ),
  };
}

export async function disableAutoPayment(customerReference: string) {
  await httpDelete(Urls.WorldpayToken(customerReference));
}
