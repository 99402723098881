import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LocalStorageKey, useLocalStorageManagerString } from '../jsUtils/localStorageService';

export interface IFeafureFlags {
  soloUi: boolean;
}

const FeatureFlagsContext = createContext<IFeafureFlags>({
  soloUi: false,
});

export function useFeatureFlags(): IFeafureFlags {
  return useContext(FeatureFlagsContext);
}

export function FeatureFlagsProvider({ children }: { children: any }) {
  const [featureFlags, setFeatureFlags] = useState({ soloUi: false });
  const { value: initialSoloUi, setValue: setInitialValue } = useLocalStorageManagerString(LocalStorageKey.SoloUi);

  // initial value for feature flags
  useEffect(() => {
    setFeatureFlags({
      soloUi: initialSoloUi === 'true',
    });
  }, []);

  // update callback for toggling feature flags
  useEffect(() => {
    (window as any).toggleSoloUi = () => {
      setInitialValue((!featureFlags.soloUi).toString());
      setFeatureFlags({ ...featureFlags, soloUi: !featureFlags.soloUi });
    };

    return () => {
      (window as any).toggleSoloUi = undefined;
    };
  }, [featureFlags]);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
