import {
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  ReactNode,
  MutableRefObject,
  UIEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import DropdownIcon from '../../../assets/icons/Button-Control icons/Expand icon.svg';
import callIfEnterKeyPressed from '../../jsUtils/callIfEnterKeyPressed';
import AppShowLoading from './AppShowLoading';

export interface IAppSelectOption {
  value: any;
  label: string;
}

function getFilteredOptions(options: IAppSelectOption[], search: string): IAppSelectOption[] {
  if (!search) {
    return options;
  }

  return options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
}

export default function AppSelect({
  options,
  onOptionSelected,
  treatInputValueAsOption,
  showNoOptions,
  shouldClearAfterSelect,
  className,
  disableSearch,
  inputClassName,
  incomingValue, // passed from react-hook-form
  getOptionPrefix,
  isOptionSelected,
  showLoading,
  onItemsContainerScroll,
  externalTriggerRef,
  extraItemOnTop,
  ...inputProps
}: {
  options: IAppSelectOption[],
  onOptionSelected?: (option: IAppSelectOption, filteredOptions: IAppSelectOption[], index: number, params: {
    selectedOnTyping?: boolean
  }) => void,
  treatInputValueAsOption?: boolean,
  inputClassName?: string,
  showNoOptions?: boolean,
  disableSearch?: boolean,
  searchEnabled?: boolean,
  shouldClearAfterSelect?: boolean,
  getOptionPrefix?: (option: string, filteredOptions: IAppSelectOption[], index?: number) => ReactNode,
  isOptionSelected?: (option: IAppSelectOption, filteredOptions: IAppSelectOption[], index: number) => boolean,
  externalTriggerRef?: MutableRefObject<HTMLElement | null>,
  showLoading?: boolean;
  onItemsContainerScroll?: UIEventHandler<HTMLDivElement>,
  extraItemOnTop?: ReactNode;
} & HTMLAttributes<HTMLInputElement> & { disabled?: boolean, readOnly?: boolean, incomingValue?: string, dataInvalid?: boolean }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedOptionDivRef = useRef<HTMLDivElement | null>(null);
  const mainContainerDivRef = useRef<HTMLDivElement | null>(null);
  const selectedOptionRef = useRef<IAppSelectOption | null>(null);
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);

  // listen to click outside of the dropdown and close options if need
  useEffect(() => {
    const hideOptionsIfClickOutSide = (e: Event) => {
      const isClickOutside = () => mainContainerDivRef.current
        && !mainContainerDivRef.current.contains(e.target as Node);
      const isClickOnExternalRef = () => externalTriggerRef?.current
        && externalTriggerRef.current.contains(e.target as Node);
      if (isClickOutside() && !isClickOnExternalRef()) {
        setIsHidden(true);
        const selectedValue = options.find((option) => option.label === inputRef.current!.value);
        if (!selectedValue && !treatInputValueAsOption) {
          inputRef.current!.value = '';
        }
      }
    };
    window.addEventListener('click', hideOptionsIfClickOutSide);

    return () => window.removeEventListener('click', hideOptionsIfClickOutSide);
  }, [setIsHidden, options, treatInputValueAsOption]);

  // when isHidden changed to true and there is a selected value, scroll that value into view
  useEffect(() => {
    if (selectedOptionDivRef.current && !isHidden) {
      selectedOptionDivRef.current!.scrollIntoView(true);
    }
  }, [isHidden]);
  const [filteredOptions, setFilteredOptions] = useState<IAppSelectOption[]>([]);
  const filterOptions = useCallback((value: string) => {
    const filtered = getFilteredOptions(options, value);
    setFilteredOptions(filtered);
    return filtered;
  }, [options]);

  // when dropdown is on bottom, we need to shove it above the input
  useEffect(() => {
    if (mainContainerDivRef.current?.classList.contains('app-select-not-in-view')) {
      const optionsDiv = mainContainerDivRef.current.querySelector('.app-select-items') as HTMLDivElement;
      if (optionsDiv && filteredOptions.length < 6) {
        const optionHeight = 37;
        optionsDiv.style.top = `-${optionHeight * filteredOptions.length}px`;
      } else if (optionsDiv != null) {
        optionsDiv.style.top = '';
      }
    }
  }, [filteredOptions]);

  // filter options by initial input value
  useEffect(() => {
    if (inputRef.current!.value !== incomingValue) {
      inputRef.current!.value = incomingValue as string || '';
    }
    filterOptions(inputRef.current!.value);
  }, [incomingValue]); // when incomingValue changes, input value should be updated too

  // when options array changed need to update filterd options
  useEffect(() => {
    // filterOptions would update filteredOptionsArray, that leads to rerendering of filteredOptions.map
    // in some cases that rerendering may happen earlier eventListeneres on the old elements has been started
    // so need to delay filterOptions call

    setTimeout(() => {
      if (inputRef.current) {
        filterOptions(inputRef.current.value);
      }
    }, 500);
  }, [options]);

  const showOptionsAndHandleInputClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setIsHidden(false);
    setFilteredOptions(options);
    if (inputProps.onClick) {
      inputProps.onClick(event as MouseEvent<HTMLInputElement>);
    }
    if (disableSearch && inputRef.current) {
      inputRef.current.blur();
    }
  }, [setIsHidden, options]);

  useEffect(() => {
    if (externalTriggerRef?.current) {
      externalTriggerRef.current.addEventListener('click', showOptionsAndHandleInputClick as any);

      return () => {
        if (externalTriggerRef?.current) {
          externalTriggerRef.current.removeEventListener('click', showOptionsAndHandleInputClick as any);
        }
      };
    }
    return () => { };
  }, [showOptionsAndHandleInputClick]);

  const selectOptionAndHideOptions = useCallback((event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    const selectedValue = JSON.parse((event.target as HTMLElement).getAttribute('data-value') as string) as IAppSelectOption;
    if (selectedValue) {
      selectedOptionRef.current = selectedValue;
    }
    const selectedIndex = (event.target as HTMLElement).getAttribute('data-index') as string;
    setIsHidden(true);
    inputRef.current!.value = selectedValue.label;
    if (onOptionSelected) {
      onOptionSelected(selectedValue, filteredOptions, +selectedIndex, { selectedOnTyping: false });
      if (shouldClearAfterSelect === true) {
        inputRef.current!.value = '';
      }
    }
  }, [onOptionSelected, setIsHidden]);

  const setFilteredAndSelected = useCallback((event: ChangeEvent<HTMLInputElement>, onTyping = false) => {
    if (inputProps.onChange) {
      inputProps.onChange(event);
    }

    if (!inputRef.current) {
      return;
    }

    const inputValue = inputRef.current.value;
    const newOptions = filterOptions(inputValue);
    if (onOptionSelected) {
      const selectedOption = inputValue ? newOptions.find((p) => p.label === inputValue) : undefined;
      const selectedOptionIndex = newOptions.findIndex((p) => p.label === inputValue);
      onOptionSelected(selectedOption || { label: inputValue, value: inputValue }, newOptions, selectedOptionIndex, { selectedOnTyping: onTyping });
      selectedOptionRef.current = selectedOption ?? null;
      if (!!selectedOption && (shouldClearAfterSelect === true || !onTyping)) {
        inputRef.current!.value = '';
        setIsHidden(true);
      }
    }
  }, [treatInputValueAsOption, onOptionSelected, filteredOptions]);

  const shouldShowOptionsContainer = !isHidden && (filteredOptions.length > 0 || showNoOptions || showLoading);

  return (
    <div
      ref={mainContainerDivRef}
      className={`app-select${className ? ` ${className}` : ''}${getOptionPrefix
        ? ' app-select-with-prefix'
        : ''}`}
    >
      {getOptionPrefix && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={showOptionsAndHandleInputClick}
          className="app-form-input-prefix"
        >
          {getOptionPrefix(selectedOptionRef.current?.value || '', filteredOptions)}
        </div>
      )}
      <input
        ref={inputRef}
        {...inputProps}
        className={inputClassName}
        type="text"
        onKeyUp={callIfEnterKeyPressed((e) => {
          setFilteredAndSelected(e as any, false);
          e.stopPropagation();
          e.preventDefault();
        })}
        autoComplete="off"
        onChange={(e) => setFilteredAndSelected(e, true)}
        onClick={showOptionsAndHandleInputClick}
        onFocus={showOptionsAndHandleInputClick as any}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={DropdownIcon}
        onClick={showOptionsAndHandleInputClick}
        alt="''"
        className="app-select-dropdown-icon"
      />
      {shouldShowOptionsContainer && (
        <div
          className="app-select-items"
          onScroll={onItemsContainerScroll}
          aria-hidden={isHidden}
        >
          {options.length === 0 && showNoOptions
            && <div className="app-select-item">{t('Form_NoOptions')}</div>}
          {extraItemOnTop && extraItemOnTop}
          {filteredOptions.map((option, index) => {
            const isSelected = !!inputRef.current && (isOptionSelected
              ? isOptionSelected(option.value, filteredOptions, index)
              : inputRef.current.value === option.value);
            return (
              <div
                role="option"
                aria-selected={isSelected}
                data-value={JSON.stringify(option)}
                data-index={index}
                tabIndex={-1}
                onClick={selectOptionAndHideOptions}
                onKeyDown={callIfEnterKeyPressed(() => selectOptionAndHideOptions)}
                className="app-select-item"
                key={option.value}
              >
                <span
                  role="option"
                  aria-selected={isSelected}
                  tabIndex={-1}
                  onClick={selectOptionAndHideOptions}
                  onKeyDown={callIfEnterKeyPressed(() => selectOptionAndHideOptions)}
                  className="app-select-item-option-prefix"
                >
                  {getOptionPrefix && getOptionPrefix(
                    option.value,
                    filteredOptions,
                    index,
                  )}
                </span>
                <span>{option.label}</span>
              </div>
            );
          })}
          {showLoading
            && (
              <div className="app-select-item">
                <AppShowLoading showLoading inline />
              </div>
            )}
        </div>
      )}
    </div>
  );
}
