import { CSSProperties, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PaxtonLogoPng from '../../../assets/MyPaxton Logo.png';
import { ReactComponent as PaxtonBackgroundSVG } from '../../../assets/icons/Modal icons/modal-background.svg';
import { formatMoney } from '../../../shared/appUIFramework/formatters/formatMoney';
import { formatPercent } from '../../../shared/appUIFramework/formatters/formatPercent';
import {
  DiscountType,
  getDiscountPercent,
  getTotalUnitsPriceWithDiscount,
  getUnitPriceWithDiscount,
  IInvoice,
  useCurrencyOptions,
} from '../../../shared/appUIFramework/appBackend/useInvoices';
import { DateFormats, useFormatDate } from '../../../shared/appUIFramework/formatters/formatDate';
import {
  isSystemManager,
  isTaxMethodUsedInCompany,
  useInternalAppsCompanyDetails,
} from '../../../shared/appUIFramework/appBackend/useKeyContact';
import { VatEvidenceType } from '../../../shared/appUIFramework/constants/Countries';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import { CompanyRegion } from '../../systems/models/ICompany';

export const contactMap: Record<CompanyRegion, { number: string, email: string }> = {
  [CompanyRegion.UnitedKingdom]: { // not required for UK
    number: '',
    email: '',
  },
  [CompanyRegion.Netherlands]: {
    number: '0031-76 3333 999',
    email: 'support@paxton-benelux.com',
  },
  [CompanyRegion.Luxembourg]: {
    number: '0031-76 3333 999',
    email: 'support@paxton-benelux.com',
  },
  [CompanyRegion.Belgium]: {
    number: '0031-76 3333 999',
    email: 'support@paxton-benelux.com',
  },
  [CompanyRegion.Norway]: {
    number: '0031-76 3333 999',
    email: 'support@paxton-benelux.com',
  },
  [CompanyRegion.France]: {
    number: '01 57 32 93 56',
    email: 'support@paxtonaccess.fr',
  },
  [CompanyRegion.Germany]: {
    number: '+49 211 8694 2200',
    email: 'verkauf@paxton-gmbh.de',
  },
  [CompanyRegion.Sweden]: {
    number: '+46200125557',
    email: 'support@paxtonaccess.se',
  },
  [CompanyRegion.Denmark]: {
    number: '+46200125557',
    email: 'support@paxtonaccess.se',
  },
  [CompanyRegion.UnitedStates]: {
    number: '(877) 438-7298',
    email: 'supportUS@paxton-access.com',
  },
  [CompanyRegion.Canada]: {
    number: '(877) 438-7298',
    email: 'supportUS@paxton-access.com',
  },
  [CompanyRegion.SouthAfrica]: {
    number: '(877) 438-7298',
    email: 'supportUS@paxton-access.com',
  },
  [CompanyRegion.Ireland]: {
    number: '01273 811011',
    email: 'support@paxton.co.uk',
  },
  [CompanyRegion.SouthAfrica]: {
    number: '+27 (0)21 4276691',
    email: 'support@paxtonaccess.co.za',
  },
};

export default function InvoicePdfPreview({
  invoice, offscreen, zoom,
}:
  {
    invoice: IInvoice,
    offscreen?: boolean,
    zoom?: number,
  }) {
  const { t } = useTranslation();
  const style: CSSProperties = offscreen
    ? {
      position: 'absolute',
      top: -9999,
      left: 0,
      overflow: 'clip',
    }
    : {
      zoom,
    };

  const { getOptionLabel: getCurrencyTranslation } = useCurrencyOptions();
  const { data: internalAppsCompany } = useInternalAppsCompanyDetails();
  const doesCompanyNeedVatNumber = !!internalAppsCompany && isTaxMethodUsedInCompany(internalAppsCompany, VatEvidenceType.VatNo);
  const company = useCompany();

  const formatDate = useFormatDate();

  const region = company?.entryRegion ?? CompanyRegion.UnitedKingdom;
  const {
    number,
    email,
  } = contactMap[region];
  const supportMessage = region === CompanyRegion.UnitedKingdom
    ? t('ForSupportWithYourInvoiceContact', { number, email })
    : region;
  const supportMessageWithDot = supportMessage.endsWith('.')
    ? supportMessage
    : `${supportMessage}.`;

  return (
    <div className="app-invoice-pdf-html-preview" id="invoicePdfHtmlPreview" style={style}>
      {offscreen && (
        <div className="app-invoice-background-image">
          <PaxtonBackgroundSVG />
        </div>
      )}
      <div>
        <div className={`app-invoice-pdf-html-preview-header ${offscreen ? 'invoice-pdf-offscreen' : ''}`}>
          <img src={PaxtonLogoPng} alt="logo" />
          <div>
            <span>{t('Invoice')}</span>
          </div>
        </div>
        <div className="app-invoice-pdf-html-preview-details">
          <div className="app-invoice-pdf-html-preview-details-left">
            <span className="app-invoice-pdf-html-to">{t('InvoiceTo')}</span>
            {invoice.invoiceAddress.companyName && <span className="app-invoice-pdf-html-preview-details-left-row">{invoice.invoiceAddress.companyName}</span>}
            {invoice.invoiceAddress.address1 && <span className="app-invoice-pdf-html-preview-details-left-row">{invoice.invoiceAddress.address1}</span>}
            {invoice.invoiceAddress.address2 && <span className="app-invoice-pdf-html-preview-details-left-row">{invoice.invoiceAddress.address2}</span>}
            {invoice.invoiceAddress.townCity && <span className="app-invoice-pdf-html-preview-details-left-row">{invoice.invoiceAddress.townCity}</span>}
            {invoice.invoiceAddress.postcode && <span className="app-invoice-pdf-html-preview-details-left-row">{invoice.invoiceAddress.postcode}</span>}
          </div>
          <div className="app-invoice-pdf-html-preview-details-right">
            <span className="app-invoice-pdf-html-to app-hidden">{t('InvoiceTo')}</span>
            <div className="app-invoice-pdf-html-preview-row">
              <span className="app-invoice-pdf-html-preview-label">
                {t('InvoiceNoLong')}
                :
              </span>
              <span className="app-invoice-pdf-html-preview-value">
                {invoice.invoiceId}
              </span>
            </div>
            {doesCompanyNeedVatNumber
              && (
                <div className="app-invoice-pdf-html-preview-row">
                  <span className="app-invoice-pdf-html-preview-label">
                    {t('CustomerVatNumber')}
                    :
                  </span>
                  <span className="app-invoice-pdf-html-preview-value">
                    {company?.vatNumber}
                  </span>
                </div>
              )}
            <div className="app-invoice-pdf-html-preview-row">
              <span className="app-invoice-pdf-html-preview-value">
                {t('InvoiceDate')}
                :
              </span>
              <span className="app-invoice-pdf-html-preview-value">
                {formatDate(invoice.invoiceDate, DateFormats.LongDate)}
              </span>
            </div>
            <div className="app-invoice-pdf-html-preview-row">
              <span className="app-invoice-pdf-html-preview-value">
                {t('AccountReference')}
                :
              </span>
              <span className="app-invoice-pdf-html-preview-value">
                {internalAppsCompany?.branchOfficeId}
              </span>
            </div>
          </div>
        </div>
        <div className="app-invoice-pdf-html-preview-table">
          <div className="app-invoice-pdf-html-preview-table-header">
            <div className="app-invoice-pdf-html-preview-table-header-row">
              <div className="app-invoice-pdf-html-preview-table-header-row-cell">
                {t('Description')}
              </div>
              <div className="app-invoice-pdf-html-preview-table-header-row-cell">
                {t('Quantity')}
              </div>
              <div className="app-invoice-pdf-html-preview-table-header-row-cell">
                {t('UnitPrice')}
                &nbsp;(
                {getCurrencyTranslation(invoice.currencyCodeId)}
                )
              </div>
              <div className="app-invoice-pdf-html-preview-table-header-row-cell">
                {t('Total')}
                &nbsp;(
                {getCurrencyTranslation(invoice.currencyCodeId)}
                )
              </div>
            </div>
          </div>
          <div className="app-invoice-pdf-html-preview-table-body">
            {invoice.billableUnits.map((item) => (
              <Fragment key={item.description}>
                <div className="app-invoice-pdf-html-preview-table-body-row" role="row">
                  <div className="app-invoice-pdf-html-preview-table-body-row-cell">
                    <div className="app-weight-600 app-mb-17">
                      {item.description}
                    </div>
                    <div className="app-invoice-pdf-html-preview-table-unit-item">
                      {t('Home_TotalDevices_EntryAppUsers')}
                    </div>
                  </div>
                  <div className="app-invoice-pdf-html-preview-table-body-row-cell">
                    {item.numberOfUnits}
                  </div>
                  <div className="app-invoice-pdf-html-preview-table-body-row-cell">
                    {formatMoney(invoice.currencyCodeId, getUnitPriceWithDiscount(item, invoice.discountsApplied, invoice.conversionFactor))}
                  </div>
                  <div className="app-invoice-pdf-html-preview-table-body-row-cell app-weight-600">
                    {formatMoney(invoice.currencyCodeId, getTotalUnitsPriceWithDiscount(item, invoice))}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          <div className="app-invoice-pdf-html-preview-table-footer" id="invoicePdfHtmlPreviewTableFooter">
            {!!invoice.Totals.TotalDiscount && (
              <div className="app-invoice-pdf-html-preview-table-footer-row">
                <div className="app-invoice-pdf-html-preview-table-footer-row-label">
                  {t('DiscretionaryDiscount')}
                  &nbsp;@&nbsp;
                  {formatPercent(getDiscountPercent(invoice.discountsApplied, DiscountType.DiscretionaryDiscount) * 100)}
                </div>
                <div className="app-invoice-pdf-html-preview-table-footer-row-value">
                  -
                  {formatMoney(invoice.currencyCodeId, invoice.Totals.TotalDiscount)}
                </div>
              </div>
            )}
            <div className="app-invoice-pdf-html-preview-table-footer-row">
              <div className="app-invoice-pdf-html-preview-table-footer-row-label">
                {t('SubTotal')}
              </div>
              <div className="app-invoice-pdf-html-preview-table-footer-row-value">
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Subtotal)}
              </div>
            </div>
            <div className="app-invoice-pdf-html-preview-table-footer-row">
              <div className="app-invoice-pdf-html-preview-table-footer-row-label">
                {t('Vat')}
                &nbsp;@&nbsp;
                {formatPercent(invoice.vatPercentage * 100)}
              </div>
              <div className="app-invoice-pdf-html-preview-table-footer-row-value">
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Vat)}
              </div>
            </div>
            <div className="app-invoice-pdf-html-preview-table-footer-row">
              <div className="app-invoice-pdf-html-preview-table-footer-row-label">
                {t('Currency')}
              </div>
              <div className="app-invoice-pdf-html-preview-table-footer-row-value">
                {getCurrencyTranslation(invoice.currencyCodeId)}
              </div>
            </div>
            <div className="app-invoice-pdf-html-preview-table-footer-row app-invoice-pdf-html-preview-table-footer-row-final">
              <div className="app-invoice-pdf-html-preview-table-footer-row-label">
                {t('Total')}
              </div>
              <div className="app-invoice-pdf-html-preview-table-footer-row-value">
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Total)}
              </div>
            </div>
          </div>
        </div>
        <div className="app-invoice-pdf-html-preview-terms">
          <span>
            {internalAppsCompany && isSystemManager(internalAppsCompany)
              ? t('PaymentTermsInAdvance')
              : t('PaymentTermsDays', { days: 30 })}
          </span>
          <span>
            {t('YouCanPayInvoice')}
          </span>
          {number && email && (
            <span>
              {supportMessageWithDot}
            </span>
          )}
        </div>
        <div className="app-invoice-pdf-html-preview-footer">
          <span>{t('PaxtonAccessLtd')}</span>
          <span>{t('PaxtonAccessTel')}</span>
          <span>{t('PaxtonVatNumber')}</span>
        </div>
        <div className="app-invoice-pdf-html-preview-paging">
          1
          {' '}
          {t('of')}
          {' '}
          1
        </div>
      </div>
    </div>
  );
}
