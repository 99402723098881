import { Redirect, Route, Switch } from 'wouter';
import { ReactNode } from 'react';
import { AppPermissionPageGuard } from '../appUIFramework/appPermisions/AppPermisionGuard';
import {
  CanNavigateToAdminsPermissions,
  CanNavigateToCompanyDetailsPermissions,
  CanNavigateToCreateAdminPermissions,
  CanNavigateToCreateEntryUserPermissions,
  CanNavigateToCreateSitePermissions,
  CanNavigateToEditAdminPermissions,
  CanNavigateToEditEntryUserPermissions,
  CanNavigateToEntryUsersPermissions,
  CanNavigateToHardwarePermissions,
  CanNavigateToHomePermissions,
  CanNavigateToMakePaymentPermissions,
  CanNavigateToPaymentOverviewPermissions,
  CanNavigateToPaymentPermissions,
  CanNavigateToSiteCostsPermissions,
  CanNavigateToSiteDetailsPermissions,
  CanNavigateToSitePermissions,
  CanNavigateToSitesPermissions,
  CanViewAdminDocuments,
} from '../appUIFramework/appPermisions/permissionsGroups';
import Home from '../../routes/home/Home';
import Profile from '../../routes/profile/Profile';
import { AccountStatusUIState, useAccountInfo } from '../appUIFramework/appBackend/useAccountInfo';
import Systems from '../../routes/systems/Systems';
import SystemsNew from '../../routes/systems/SystemsNew';
import System from '../../routes/systems/System';
import SystemTabsEntryUsers from '../../routes/systems/SystemTabsEntryUsers';
import SystemEntryUsersNew from '../../routes/systems/SystemEntryUsersNew';
import SystemEntryUser from '../../routes/systems/SystemEntryUser';
import SystemTabsHardware from '../../routes/systems/SystemTabsHardware';
import SystemTabsSystemDetails from '../../routes/systems/SystemTabsSystemDetails';
import PaymentOverview from '../../routes/payment/PaymentOverview';
import PaymentInvoicesPdf from '../../routes/payment/PaymenInvoicesPdf';
import PaymentPayments from '../../routes/payment/PaymentPayments';
import AdminPeople from '../../routes/admin/AdminPeople';
import AdminPeopleCreate from '../../routes/admin/AdminPeopleCreate';
import AdminPeopleDetails from '../../routes/admin/AdminPeopleDetails';
import AdminCompanyDetails from '../../routes/admin/AdminCompanyDetails';
import PageNotFound from '../../routes/PageNotFound';
import { useAppNavigationLinks } from './AppNavigationMenu';
import SystemTabsCosts from '../../routes/systems/SystemTabsCosts';
import PaymentTermsAndConditions from '../../routes/payment/PaymentTermsAndConditions';
import AdminDocuments from '../../routes/admin/AdminDocuments';
import { isTaxMethodUsedInCompany, useInternalAppsCompanyDetails } from '../appUIFramework/appBackend/useKeyContact';
import { VatEvidenceType } from '../appUIFramework/constants/Countries';
import AppErrorPage from '../../routes/AppErrorPage';
import { useFeatureFlags } from '../appUIFramework/featureFlags';
import HomeSolo from '../../routes/home/HomeSolo';

function AccountStatusGuard({ accountStatuses, children }: { accountStatuses: AccountStatusUIState[], children: ReactNode }) {
  const { accountInfo } = useAccountInfo();
  const isCurrentStatusAllowed = accountStatuses.includes(accountInfo?.accountStatus ?? AccountStatusUIState.Ok);
  return (isCurrentStatusAllowed
    ? <>{children}</>
    : <Redirect to="/" />);
}
export default function AppRouteSwitch() {
  const appNavigationLinks = useAppNavigationLinks();
  const { soloUi } = useFeatureFlags();
  const homePageRoute = appNavigationLinks.find((p) => !p.disabled)!.to;
  if (!homePageRoute) {
    throw new Error('home page route has to be defined all the time');
  }

  const { data: internalAppsCompany } = useInternalAppsCompanyDetails();

  return (
    <Switch>
      <Route path="/">
        <Redirect to={homePageRoute} />
      </Route>
      <Route path="/home">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToHomePermissions}>
            { soloUi ? <HomeSolo /> : <Home /> }
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/systems">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToSitesPermissions}>
            <Systems />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/0">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateSitePermissions}>
            <SystemsNew />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSitePermissions}>
            <System />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/costs">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteCostsPermissions}>
            <SystemTabsCosts />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/entry-users">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEntryUsersPermissions}>
            <SystemTabsEntryUsers />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/entry-users/0">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateEntryUserPermissions}>
            <SystemEntryUsersNew />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/entry-users/:siteId">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEditEntryUserPermissions}>
            <SystemEntryUser />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/hardware">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToHardwarePermissions}>
            <SystemTabsHardware />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/systems/:siteId/system-details">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToSiteDetailsPermissions}>
            <SystemTabsSystemDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/payment">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentPermissions}>
          <PaymentOverview />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/overview">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentOverviewPermissions}>
          <PaymentOverview />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/overview/:invoiceId">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentOverviewPermissions}>
          <PaymentInvoicesPdf />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/payments">
        <AppPermissionPageGuard permissions={CanNavigateToMakePaymentPermissions}>
          <PaymentPayments />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/payment/terms">
        <AppPermissionPageGuard permissions={CanNavigateToPaymentPermissions}>
          <PaymentTermsAndConditions />
        </AppPermissionPageGuard>
      </Route>
      <Route path="/admin">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToAdminsPermissions}>
            <AdminPeople />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people">
        <AccountStatusGuard
          accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}
        >
          <AppPermissionPageGuard permissions={CanNavigateToAdminsPermissions}>
            <AdminPeople />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people/0">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok]}>
          <AppPermissionPageGuard permissions={CanNavigateToCreateAdminPermissions}>
            <AdminPeopleCreate />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/people/:id">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToEditAdminPermissions}>
            <AdminPeopleDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      <Route path="/admin/company-details">
        <AccountStatusGuard accountStatuses={[AccountStatusUIState.Ok, AccountStatusUIState.Limited]}>
          <AppPermissionPageGuard permissions={CanNavigateToCompanyDetailsPermissions}>
            <AdminCompanyDetails />
          </AppPermissionPageGuard>
        </AccountStatusGuard>
      </Route>
      {!!internalAppsCompany && isTaxMethodUsedInCompany(internalAppsCompany, VatEvidenceType.TaxCertificate) ? (
        <Route path="/admin/documents">
          <AppPermissionPageGuard permissions={[...CanViewAdminDocuments]}>
            <AdminDocuments />
          </AppPermissionPageGuard>
        </Route>
      ) : <></>}
      <Route path="/error/:code">
        <AppErrorPage />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
