import { KeyedMutator } from 'swr';
import { httpGetJson, httpPostJson, httpPut } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';

export enum AppNotificationType {
  InvoicesDue = 'InvoicesDue',
  InvoicesOverdue = 'InvoicesOverdue',
  InvoicesPaid = 'InvoicesPaid',
  CardExpired = 'CardExpired',
  SiteAdded = 'SiteAdded',
  SiteRemoved = 'SiteRemoved',
  PermissionsUpdated = 'PermissionsUpdated',
  NeedUpdateTermsAndConditions = 'NeedUpdateTermsAndConditions',
  ReductionInService = 'ReductionInService',
  AccountOnHold = 'AccountOnHold',
  InvoiceBecomeOverdueIn7Days = 'InvoiceBecomeOverdueIn7Days',
  InvoicePaymentFailedManual = 'InvoicePaymentFailedManual',
  InvoicePaymentFailedAuto = 'InvoicePaymentFailedAuto',
}

export interface IAppNotification {
  id: string;
  type: AppNotificationType;
  createdAt: string;
  jsonPayload: string;
  read: boolean;
}

// should be use for debugged purposes to speed up fixes of ui bugs if any
// const debugNotifications = {
//   notifications: {
//     notifications: [
//       {
//         type: AppNotificationType.InvoicesDue,
//         jsonPayload: '{"invoiceNumber": 1}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '1',
//       },
//       {
//         type: AppNotificationType.InvoicesOverdue,
//         jsonPayload: '{"invoiceNumber": 2}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '2',
//       },
//       {
//         type: AppNotificationType.InvoicesPaid,
//         jsonPayload: '{"invoiceNumber": 3}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '3',
//       },
//       {
//         type: AppNotificationType.CardExpired,
//         jsonPayload: '',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '4',
//       },
//       {
//         type: AppNotificationType.SiteAdded,
//         jsonPayload: '{"siteName": "Site 1"}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '5',
//       },
//       {
//         type: AppNotificationType.PermissionsUpdated,
//         jsonPayload: '{"toRole": "PaymentAdmin"}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '6',
//       },
//       {
//         type: AppNotificationType.NeedUpdateTermsAndConditions,
//         jsonPayload: '',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '7',
//       },
//       {
//         type: AppNotificationType.ReductionInService,
//         jsonPayload: '',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: true,
//         id: '8',
//       },
//       {
//         type: AppNotificationType.AccountOnHold,
//         jsonPayload: '',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: true,
//         id: '9',
//       },
//       {
//         type: AppNotificationType.CardExpired,
//         jsonPayload: '{"siteName": "Site 1"}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '10',
//       },
//       {
//         type: AppNotificationType.InvoiceBecomeOverdueIn7Days,
//         jsonPayload: '{"invoiceNumber": "123"}',
//         createdAt: '2021-01-01T00:00:00.000Z',
//         read: false,
//         id: '11',
//       },
//     ],
//   },
// };

export function useAppNotifications(): { notifications?: IAppNotification[], mutate: KeyedMutator<{ items: IAppNotification[] }> } {
  const { data, mutate } = useSWRAndHandleErrors<{ items: IAppNotification[] }>(
    Urls.Notifications,
    () => httpGetJson(Urls.Notifications, { queryParams: { page: 0, pageSize: 100 } }),
  );
  return {
    notifications: data?.items,
    mutate,
  };
}

export function useAppNotificationsUnreadCount(): {
  unreadCount: number,
  mutate: KeyedMutator<{ unreadCount: number }>
  } {
  const { data, mutate } = useSWRAndHandleErrors<{ unreadCount: number }>(
    Urls.NotificationsUnreadCount,
    httpGetJson,
  );
  return { unreadCount: data?.unreadCount || 0, mutate };
}

export async function createNotification(notification: { type: AppNotificationType }) {
  await httpPostJson(Urls.Notifications, notification);
}

export async function readNotification(notificationId: string) {
  await httpPut(Urls.NotificationRead(notificationId), {});
}

export async function readAllNotifications() {
  await httpPut(Urls.NotificationReadAll, {});
}
