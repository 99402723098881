import i18n from 'i18next';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';
import { useEffect } from 'react';
import germanTranslation from './de-DE.json';
import englishTranslation from './en-GB.json';
import usTranslation from './en-US.json';
import frenchTranslation from './fr-FR.json';
import netherlandsTranslation from './nl-NL.json';
import swedishTranslations from './sv-SE.json';
import danishTranslations from './da.json';
import norwegianTranslations from './no.json';
import spanishLatinAmericaTranslations from './es-419.json';
import { B2CLangCode } from '../appUIFramework/constants/B2CLangCode';
import { DateFormats } from '../appUIFramework/formatters/formatDate';
import { InternalAppsCompany } from '../appUIFramework/appBackend/useKeyContact';
import { LocalStorageKey, useLocalStorageManagerString } from '../jsUtils/localStorageService';
import {
  CountryCode,
  getCountryLanguageByCountryId,
} from '../appUIFramework/constants/Countries';

export const LangEmailMap: Record<B2CLangCode, string> = {
  [B2CLangCode.English]: 'support@paxton.co.uk',
  [B2CLangCode.French]: 'support@paxton.co.uk',
  [B2CLangCode.EnglishUS]: 'supportUS@paxton-access.com',
  [B2CLangCode.German]: 'verkauf@paxton-gmbh.de',
  [B2CLangCode.Netherlands]: 'support@paxton-benelux.com',
  [B2CLangCode.Swedish]: 'support@paxtonaccess.se',
  [B2CLangCode.Danish]: 'support@paxtonaccess.se',
  [B2CLangCode.Norwegian]: 'support@paxton-benelux.com',
  [B2CLangCode.SpanishLatinAmerica]: 'support@paxtonaccess.co.za',
};

export const resources = {
  [B2CLangCode.English]: { translation: englishTranslation },
  [B2CLangCode.French]: { translation: frenchTranslation },
  [B2CLangCode.EnglishUS]: { translation: usTranslation },
  [B2CLangCode.German]: { translation: germanTranslation },
  [B2CLangCode.Netherlands]: { translation: netherlandsTranslation },
  [B2CLangCode.Swedish]: { translation: swedishTranslations },
  [B2CLangCode.Danish]: { translation: danishTranslations },
  [B2CLangCode.Norwegian]: { translation: norwegianTranslations },
  [B2CLangCode.SpanishLatinAmerica]: { translation: spanishLatinAmericaTranslations },
};

export function useAppLocalisation() {
  const { value: appLanguage, setValue: setAppLanguage } = useLocalStorageManagerString(LocalStorageKey.AppLanguage);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setAppLanguage(lang);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const countryCode = urlParams.get('countryCode') as CountryCode;
    if (countryCode) {
      changeLanguage(countryCode.toLowerCase());
    } else {
      const lang = appLanguage || B2CLangCode.English;
      i18n.changeLanguage(lang);
    }
  }, [appLanguage]);

  return {
    init: (internalAppsInfo: InternalAppsCompany) => {
      const lang = appLanguage
        || (internalAppsInfo?.address && getCountryLanguageByCountryId(internalAppsInfo?.address.countryId))
        || B2CLangCode.English;
      changeLanguage(lang);
    },
    lang: (appLanguage ?? i18n.language) as B2CLangCode,
    changeLanguage,
  };
}

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: B2CLangCode.English,
  supportedLngs: Object.keys(resources),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format, lng) => {
      if (value instanceof Date) {
        switch (format) {
          case 'DATE_SHORT':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.DATE_SHORT);
          case 'DATE_MED':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.DATE_MED);
          case 'DATE_FULL':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.DATE_FULL);
          case 'DATE_HUGE':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.DATE_HUGE);
          case 'TIME_SIMPLE':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.TIME_SIMPLE);
          case 'TIME_24_SIMPLE':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.TIME_24_SIMPLE);
          case 'DATE_MOBILE':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString({ month: 'numeric', day: 'numeric', year: '2-digit' });
          case 'MM_DD_YYYY':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString({ month: '2-digit', day: '2-digit', year: 'numeric' });
          case 'DATE_LONG':
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString({
              month: 'short', day: '2-digit', year: 'numeric',
            });
          case DateFormats.LongDateMobile:
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString({
              month: 'short', day: '2-digit', year: '2-digit',
            });
          default:
            return DateTime.fromJSDate(value).setLocale(lng as string).toLocaleString(DateTime.DATE_MED);
        }
      }
      return value;
    },
  },
  resources,
});
