import { useEffect, useState } from 'react';
import './styles.scss';
import { getAccessToken, initClaims } from './shared/backend/auth/auth';
import AppNotificationsListener from './shared/appUIFramework/components/AppNotifications/AppNotificationsListener';
import AppAccountStatusBar from './AppAccountStatusBar';
import FirstTimeSignedInPopup from './shared/appUIFramework/appTermsAndConditions/FirstTimeSignedInPopup';
import { showPopup } from './shared/appUIFramework/popup/AppPopup';
import AppNavigationMenu from './shared/appShellComponents/AppNavigationMenu';
import AppRouteSwitch from './shared/appShellComponents/AppRouteSwitch';
import { useLogoutOnIDLE } from './shared/appUIFramework/useLogoutOnIDLE';
import { getCompany } from './shared/appUIFramework/appBackend/useCompany';
import { AdminUserRole } from './shared/appUIFramework/appBackend/useProfileDetails';
import {
  getAccountType,
  getInternalAppsCompanyDetails,
  isTaxMethodUsedInCompany,
  isVatRegisteredResellerCompany,
} from './shared/appUIFramework/appBackend/useKeyContact';
import { useLogoutOnImpersonationTimeout } from './shared/appUIFramework/useLogoutOnImpersonationTimeout';
import {
  VatEvidenceType,
} from './shared/appUIFramework/constants/Countries';
import { InternalAppsError } from './shared/backend/errors/internalAppsError';
import { useLogout } from './shared/appUIFramework/hooks/useLogout';
import { handleHttpRequestError } from './shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';
import {
  useIsAccountRestricted,
} from './shared/appUIFramework/appBackend/useAccountInfo';
import { useAppLocalisation } from './shared/i18n/config';
import AppShowLoading from './shared/appUIFramework/components/AppShowLoading';
import AppUserDeletedLister from './shared/appUIFramework/components/AppNotifications/AppUserDeletedListener';
import { FeatureFlagsProvider } from './shared/appUIFramework/featureFlags';

function useAccessInit() {
  const [claimsInitialized, setClaimsInitialized] = useState(false);
  const { init: initLanguage } = useAppLocalisation();

  const logout = useLogout();
  useLogoutOnIDLE();
  useLogoutOnImpersonationTimeout();

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (e.altKey && e.key === 'k') {
        const accessToken = await getAccessToken();
        navigator.clipboard.writeText(accessToken);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    initClaims()
      .then(async (claims) => {
        setClaimsInitialized(true);
        const [company, internalAppsInfo] = await Promise.all([getCompany(), getInternalAppsCompanyDetails(claims.customerReference)]);
        const minDate = '0001-01-01T00:00:00';
        const termsAndConditionsNotSet = !company.termsAndConditionsAcceptedAt || company.termsAndConditionsAcceptedAt === minDate;
        const currentUserIsAPaymentAdmin = claims.userRole === AdminUserRole.PaymentAdmin;
        const requiredTermsAndConditionsPopup = currentUserIsAPaymentAdmin && termsAndConditionsNotSet;

        initLanguage(internalAppsInfo);

        if (requiredTermsAndConditionsPopup) {
          showPopup({
            reactNode: <FirstTimeSignedInPopup
              address={internalAppsInfo.address}
              isOnlyAutopaymentEnabled={isVatRegisteredResellerCompany(internalAppsInfo)}
              isTaxCertificatedRequired={isTaxMethodUsedInCompany(internalAppsInfo, VatEvidenceType.TaxCertificate)}
              isVatNumberNeeded={isTaxMethodUsedInCompany(internalAppsInfo, VatEvidenceType.VatNo)}
              accountType={getAccountType(internalAppsInfo.typeOfCustomer)}
            />,
            disableCloseOnOverlay: true,
          });
        }
      })
      .catch(async (e) => {
        if (e instanceof InternalAppsError) {
          console.log('InternalAppsError', e);
        } else {
          await handleHttpRequestError(e);
          await logout();
        }
      });
  }, []);
  const canRender = claimsInitialized;
  return { canRender };
}

export default function AppForAuthenticatedUser() {
  const onMenuOpenOverlayClicked = () => {
    (document.getElementById('menu-visibility-handler-checkbox') as HTMLInputElement).checked = false;
  };

  const { canRender } = useAccessInit();
  const isRestrictionBannerShown = canRender ? useIsAccountRestricted() : false;
  const appHeightRestrictionClass = isRestrictionBannerShown ? 'app-restricted-height' : '';
  return (
    <FeatureFlagsProvider>
      {canRender && (
        <div className="app-wrapper">
          <AppAccountStatusBar />
          <div className={`app ${appHeightRestrictionClass}`}>
            <input
              id="menu-visibility-handler-checkbox"
              name="menu-visibility-handler-checkbox"
              className="app-navigation-visible-state"
              type="checkbox"
              hidden
            />
            <div className="app-navigation-desktop-container">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="app-navigation-overlay" onClick={onMenuOpenOverlayClicked} />
              <AppNavigationMenu />
            </div>
            <div className="app-portal">
              <AppRouteSwitch />
            </div>
          </div>
        </div>
      )}
      {canRender && <AppNotificationsListener />}
      {canRender && <AppUserDeletedLister />}
      {!canRender && <AppShowLoading showLoading />}
    </FeatureFlagsProvider>
  );
}
